// todo: 按说要写到中间件中， 也可获取用户信息了
const errorHandle = (errorMsg) => {
    /**
     * @description: 主要完成错误收集： 错误位置， 错误信息， 时间， 客户机信息
     * */
    if (process.server) {
        console.log('errorHandle', errorMsg)
        // const fs = require( 'fs' );
        // const dayjs = require("dayjs");
        // fs.readFile( './error.log' , (err, data) => {
        //     if (err) throw err;
        //     let _time = dayjs().format('YYYY-MM-DD HH:mm:ss')
        //     let _data = `${_time} - ${errorMsg}\n`
        //     fs.writeFile('./error.log', _data, {flag: 'a'}, (err) => {
        //         if (err) {
        //             console.error(err)
        //         }
        //     })
        // })
    }

}

export default errorHandle;