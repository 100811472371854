import errorHandle from "./errorHandle";
let baseUrl = process.client ? import.meta.env.VITE_CLIENT_API_HOST : import.meta.env.VITE_API_HOST


const request = (url, options={method: 'get'})  => {
    const reqUrl = baseUrl + url
    return new Promise((resolve, reject) => {
        useFetch(reqUrl, { ...options,
            onResponse({ request, response, options }) {
            },
            onResponseError({ request, response, options }) {
            }
        }).then(({ data, error, refresh }) => {
            if (error.value) {
                if (error.value.toString().includes(': 560')) {
                    resolve({code: 560, msg: '请求次数频繁'})
                } else {
                    resolve({code: 500, msg: error.value})
                }
                return
            }
            if (process.client) {
                let timer;
                timer = setInterval(() => {
                    if (data.value) {
                        clearInterval(timer)
                        eventHandling(data, refresh, resolve, reject)
                    }
                }, 20)
            }
            if (process.server) {
                eventHandling(data, refresh, resolve, reject)
            }
        }).catch((err) => {
            console.log("useHttp2:", err)
            errorHandle(`useHttp > catch, reqUrl: ${reqUrl}, error: ${err.value}`)
            resolve(err)
        })
    })
}


function eventHandling(data, refresh, resolve, reject) {
    if (data.value.code === 200) {
        resolve(data.value, refresh)
    } else {
        throw ({code: data.value.code, msg: data.value.msg})
    }
}

export default request;